






































/* eslint-disable */
export default {
  name: "social"
};
